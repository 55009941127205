
<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width='70px'>
        <div class="add-area">
            <img :src="addIcon" alt="add" @click="add"/>
            <span class="add" @click="add">添加</span>
            <span v-if="ruleForm.numLimit">最多添加</span>
            <span v-if="ruleForm.numLimit" class="limit-count"> {{ruleForm.numLimit}}个 </span>
            <span v-if="ruleForm.numLimit">亲情号</span>
        </div>
        <div v-if="ruleForm.list.length != 0">
            <div v-for="(item, index) in ruleForm.list" :key="index">
                <div v-if="ruleForm.list[index].guanXi == '班主任' && index < isTeacher(index)" class="detail-item" :class="ruleForm.list[index].guanXi == '班主任' ? 'teacher' : ''">
                    <img class="avatar" :src="avatar" alt="">
                    <span class="mes">班主任 / {{ruleForm.list[index].tel}}</span>
                </div>
                <div v-if="ruleForm.list[index].guanXi != '班主任' && index < (ruleForm.numLimit || index + 1)" class="detail-item">
                    <span class="relation">{{ruleForm.list[index].guanXi || '-'}}</span>
                    <span class="phone">{{ruleForm.list[index].tel}}</span>
                    <img class="edit" :src="edit" alt="" @click="editFamily(ruleForm.list[index])">
                    <img class="del" :src="delIcon" alt="" @click="del(ruleForm.list[index].id)">
                </div>
            </div>
        </div>
        <div v-else style="text-align: center; font-size: 14px;color: #afb1b2; margin: 100px auto">还未添加亲情号</div>
        <!-- <div style="text-align: right; margin-top: 35px">
            <el-button @click="cancel('ruleForm')" class="dialog-reset-button">取消</el-button>
            <el-button type="primary" class="dialog-primary-button" @click="cancel('ruleForm')">确定</el-button>
        </div> -->
        <dialog-wrapper
            :dialogObj="addDialogObj"
            @handleClose="closeAdd"
        >
            <div style="display: flex">
                <span style="display: inline-block; width: 70px; text-align: right; line-height: 32px">关系：</span>
                <el-input placeholder="请输入关系" v-model="guanXi" maxlength="10" clearable style="margin-bottom: 20px"></el-input>
            </div>
            <div style="display: flex">
                <span style="display: inline-block; width: 70px; text-align: right; line-height: 32px">手机号：</span>
                <el-input placeholder="请输入亲情手机号" v-model="tel" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
            </div>
            <div style="text-align: right; margin-top: 35px">
                <el-button @click="closeAdd" class="dialog-reset-button">取消</el-button>
                <el-button type="primary" class="dialog-primary-button" @click="submitAdd">保存</el-button>
            </div>
        </dialog-wrapper>
    </el-form>
</template>

<script>
import { del } from 'vue'
import { DialogWrapper } from "common-local";
export default {
    components: { DialogWrapper },
    props: {
        ruleForm: {
            type: Object
        },
        rules: {
            type: Object
        },
        familyCodeDialogFormDataList: {
            type: Array
        }
    },
    data() {
        return {
            addDialogObj: {
                title: "添加亲情号",
                dialogVisible: false,
                width: "500px",
            },
            id: "",
            tel: "",
			guanXi: "",
        }
    },
    computed:{
        addIcon() {
            return require('../../assets/images/characterGroup_add.png')
        },
        edit() {
            return require('../../assets/images/edit-2.png')
        },
        delIcon() {
            return require('../../assets/images/del-card.png')
        },
        avatar() {
            return require('../../assets/images/teacher.png')
        }
    },
    methods: {
        init() {
            this.ruleForm.list = this.familyCodeDialogFormDataList;
        },
        /**
         * @Description: 根据亲情号限制数量和班主任数量控制班主任显示个数
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-09 14:32:05
         * @param {*} index
         */        
        isTeacher(index) {
            if (this.ruleForm.numLimit) {
                if (index < this.ruleForm.numLimit) {
                    return index + 1
                } else {
                    return this.ruleForm.numLimit
                }
            } else {
                return index + 1
            }
        },
        /**
         * @Description: 删除亲情号
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-02 17:27:51
         * @param {*} index
         */        
        del(id) {
            this.$emit('del', id)
        },
        /**
         * @Description: 打开添加亲情号弹窗
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-02 17:28:19
         */        
        add() {
            if (this.ruleForm.numLimit && this.familyCodeDialogFormDataList.length >= this.ruleForm.numLimit) {
                return this.$message.warning('已达到最大数量，无法再添加');
            }
            this.addDialogObj.title = '添加亲情号'
            this.id = ""
            this.tel = ""
            this.guanXi = ""
            this.addDialogObj.dialogVisible = true
            this.cancel()
        },
        editFamily(data) {
            this.addDialogObj.title = '编辑亲情号'
            this.tel = data.tel
            this.guanXi = data.guanXi ? data.guanXi : '-'
            this.id = data.id
            this.addDialogObj.dialogVisible = true
            this.cancel()
        },
        /**
         * @Description: 关闭亲情号弹窗
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-02 17:29:17
         */        
        cancel() {
            this.$emit('cancel')
        },
        /**
         * @Description: 清除数据
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-02 17:28:57
         */        
        clearData() {
            this.ruleForm.list = [{
                tel: '',
                guanXi: ''
            }]
        },
        /**
         * @Description: 添加亲情号
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-02 17:17:38
         */        
        submitAdd() {
            if (this.guanXi.includes('班主任')) {
                return this.$message.warning('请输入其他关系名称');
            }
            if (this.id) {
                this.$emit('edit', {id: this.id, tel: this.tel, guanXi: this.guanXi})
            } else {
                this.$emit('add', {tel: this.tel, guanXi: this.guanXi, id : this.id})
            }
            // this.addDialogObj.dialogVisible = false
            // this.$emit('openFamilyCode')
        },
        /**
         * @Description: 关闭添加弹窗
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: pyl
         * @Date: 2024-07-02 17:13:49
         */        
        closeAdd() {
            this.addDialogObj.dialogVisible = false
            this.$emit('openFamilyCode')
        }
    },
    watch: {
        familyCodeDialogFormDataList: {
            handler() {
                this.init()
            },
            deep: true,
            immediate: true
        }
    }

}
</script>

<style scoped lang="scss">
.el-icon-circle-close {
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    color: #606266;
}
img {
    vertical-align: middle;
    cursor: pointer;
}
.add-area {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #808487;
    margin-bottom: 25px;
}
.add {
    color: #2E8AE6;
    display: inline-block;
    padding-left: 5px;
    margin-right: 25px;
    cursor: pointer;
}
.limit-count {
    color: #2E8AE6;
}
.detail-item {
    height: 48px;
    border-radius: 6px;
    border: 1px solid #E1E3E6;
    margin-bottom: 12px;
    span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #2B2F33;
        line-height: 48px;
    }
    .relation {
        display: inline-block;
        margin-left: 16px;
        width: 150px;
        text-align: left;
    }
    .phone {
        display: inline-block;
        width: 185px;
        margin-left: 2px;
    }
    .del, .edit {
        cursor: pointer;
    }
    .edit {
        width: 16px;
        height: 16px;
        margin-right: 15px;
    }
}
.teacher {
    border: none;
    border-radius: 0;
    background-image: url("~@/assets/images/teacher-bg.png");
    background-size: 424px 48px;
    position: relative;
    .avatar {
        position: absolute;
        width: 80px;
        top: -17px;
        left: 0px;
        right: 25px;
    }
    .mes {
        position: absolute;
        left: 130px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #D96C36;
        background: linear-gradient(0deg, #E69422 0%, #D97A36 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>
