<template>
    <div>
        <el-form ref="dialogForm" :model="rulesForm" :rules="rules" class="common-form" label-width="94px" label-position="right">
            <el-form-item label="校园地点" prop="addressName">
                    <el-input v-model="rulesForm.addressName" placeholder='请输入校园地点' class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="地点类型" prop="addressType">
                    <el-select v-model="rulesForm.addressType"  placeholder='请选择地点类型' class="input-width-medium" >
                        <el-option v-for="(itm, index) in addressTypeList" :value="itm.value" :label="itm.label" :key="index" />
                    </el-select>
            </el-form-item>
            <el-form-item v-if="rulesForm.parentId == ''" label="所在区域" prop="areaCascader" >
                <el-cascader  :rules="[{ type: 'array' }]"  placeholder='请选择所在区域'   v-model="rulesForm.areaCascader" :props="cascaderProps" :options="optionsData"  class="input-width-medium"></el-cascader>
            </el-form-item>
            <el-form-item label="描述"  prop="remark">
                <el-input v-model="rulesForm.remark" placeholder='请输入描述' show-word-limit type="textarea" rows="5" maxlength="300" class="input-width-medium"></el-input>
            </el-form-item>

            <!-- <el-form-item v-for="(item, index) in dialogForm" :label="item.label" :key="index" :prop="item.key"
                    label-width="80px">
                    <el-input v-if="item.type === 'textarea'" v-model="rulesForm[item.key]" type="textarea" rows="5"
                        :placeholder="item.placeholder" show-word-limit maxlength="300" style="width: 350px" />
                    <el-input v-else-if="item.type === 'input'" :maxlength="item.limt" show-word-limit v-model="rulesForm[item.key]"
                        clearable :placeholder="item.placeholder" style="width: 350px" />
                    <el-select v-else-if="item.type === 'select'" v-model="rulesForm[item.key]" filterable clearable
                        :placeholder="item.placeholder" style="width: 350px">
                        <el-option v-for="(itm, index) in item.list" :value="itm.value" :label="itm.label" :key="index" />
                    </el-select>
                </el-form-item> -->
			<div style="text-align: right;width:546px;">
				<xk-button @click="handleClose" type="info">取 消</xk-button>
            	<xk-button @click="handleSubmit" type="primary">确 定</xk-button>
        	</div>
            
        </el-form>
        
    </div>
</template>

<script>
export default {
    name: 'dialogFormData',
    props: {
        rulesForm: {
            type: Object
        },
        rules: {
            type: Object
        },
        dialogForm: {
            type: Array
        },
        optionsData: {
            type: Array
        },
        addressTypeList:{
            type: Array
        },
    },
    data(){
        let _this = this;
        return{
            cascaderProps: {
        value: 'value',
        label: 'label',
        leaf: "leaf",
        lazy: true,
        lazyLoad(node, resolve) {
          console.log('fafafaf',node)
          const level = node.level;
          if (level === 1) {
            const id = node.value;
            _this.$eventBus.$emit('handleChange', {
              id: id,
              level: level,
              fn: resolve,
              node: node
            })

          } else if (level == 2) {
            const id = node.value;
            _this.$eventBus.$emit('handleChange', {
              id: id,
              level: level,
              fn: resolve,
              node: node
            })
          }else if (level == 3) {
            const id = node.value;
            _this.$eventBus.$emit('handleChange', {
              id: id,
              level: level,
              fn: resolve,
              node: node
            })
          }
        }
      }
        }
    },
    methods: {
        handleClose() {
            this.$emit('cancel')
        },
         // 地区选择
        handleChange(data) {
        this.$eventBus.$emit('handleChange', data)
        },
        handleSubmit() {
            this.$refs.dialogForm.validate(val => {
                if (val) {
                    this.$emit('success')
                }
            })
        }
    }
}
</script>

<style scoped>
</style>
