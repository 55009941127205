<template>
    <div class="main-page" style="margin-top: 0;">
        <!-- 校内管理 > 设备管理 -->
        <template v-if="show">
            <tabbed-page
                :tabList="tabList"
                @changeTabs="changeTabs"
            >
            </tabbed-page>
        </template>
        <div class="page-box">
            <component
                :is="comps[idx]"
                @switchClick="switchClick"
                @checkingInIdChange="checkingInIdChange"
                @routerReturnChange="routerReturnChange"
                @commodityPurchase="commodityPurchase"
                @goSchoolAddress="handleGoSchoolAddress"
                :checkingInId="checkingInId"
                :routerReturn="routerReturn"
            >
            </component>
        </div>
    </div>
</template>

<script>
import TabbedPage from "../../Sub/TabbedPage/index.vue";
import {tabPageHasAuth} from "@/libs/tabbedPageAuth.js";
import {mapState} from "vuex";
export default {
    name: "DeviceManagement",
    components: {
        MachineManagement: () =>
            import(
                /* webpackChunkName:'MachineManagement' */ "@/components/scrollWrapper/DeviceManagement/equipmentManagement/MachineManagement"
            ),
        DealFlow: () =>
            import(
                /* webpackChunkName:'DealFlow' */ "@/components/scrollWrapper/DeviceManagement/equipmentManagement/DealFlow"
            ),
        TabbedPage,
    },
    data() {
        return {
            show: true,
            // activeTab: "1",
            currentComponent: "",
            checkingInId: undefined,
            routerReturn: undefined,
            comps: ["MachineManagement", "DealFlow"],
            tabList: [
                {
                    id: "1",
                    title: "设备管理",
                    vHasPermi: ["equipment:all"],
                    com: "MachineManagement",
                },
                {
                    id: "2",
                    title: "设备交易流水",
                    vHasPermi: ["equipmentFlow:all"],
                    com: "DealFlow",
                },
            ],
            idx: 0,
        };
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    created() {
        this.checkAuth();
        this.init();
    },
    methods: {
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map((item) => item.com);
            console.log(this.tabList, this.comps, "this.tabList");
        },
        /**
         * @Description: 切换tab
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-08-16 10:01:06
         */
        changeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, "sel", true);
                    this.idx = index;
                } else {
                    this.$set(item, "sel", false);
                }
            });
        },
        switchClick(page) {
            this.currentComponent = page;
        },
        checkingInIdChange(id) {
            this.checkingInId = id;
        },
        routerReturnChange(path) {
            this.routerReturn = path;
        },
        checkAuth() {
            let res = this.permissions.indexOf("equipment:all");
            let res2 = this.permissions.indexOf("equipmentFlow:all");

            if (res !== -1) {
                this.activeTab = "1";
            } else if (res2 !== -1) {
                this.activeTab = "2";
            } else {
                this.show = false;
            }
        },
        handlerChangeTabs(data) {
            this.activeTab = data.id;
            console.log(this.activeTab);
        },
        handleGoSchoolAddress() {
            this.$emit("changeComp", "schoolAddress");
        },
        commodityPurchase() {
            this.$emit("changeComp", "commodityPurchase");
        },
    },
};
</script>

<style scoped lang="scss">
.device-management {
    margin-top: 10px;
    .tabs-list {
        display: flex;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background-color: #fff;
        border-radius: 4px 0 0 4px;
        .tabs-item {
            width: 100px;
            position: relative;
            text-align: center;
            font-size: 14px;
            color: #333333;
            cursor: pointer;
        }

        .tabs-item.current {
            color: #409eff;
        }

        .line {
            position: absolute;
            bottom: 2px;
            left: 25%;
            width: 50%;
            height: 2px;
            background-color: #409eff;
        }
    }
}
.center-main {
    padding-left: 0 !important;
    padding-top: 0 !important;
}
</style>
