// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/teacher-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".el-icon-circle-close[data-v-4664ba3e]{margin-left:10px;margin-top:10px;cursor:pointer;color:#606266}img[data-v-4664ba3e]{vertical-align:middle;cursor:pointer}.add-area[data-v-4664ba3e]{font-family:Microsoft YaHei;font-weight:400;font-size:14px;color:#808487;margin-bottom:25px}.add[data-v-4664ba3e]{display:inline-block;padding-left:5px;margin-right:25px;cursor:pointer}.add[data-v-4664ba3e],.limit-count[data-v-4664ba3e]{color:#2e8ae6}.detail-item[data-v-4664ba3e]{height:48px;border-radius:6px;border:1px solid #e1e3e6;margin-bottom:12px}.detail-item span[data-v-4664ba3e]{font-family:Microsoft YaHei;font-weight:400;font-size:14px;color:#2b2f33;line-height:48px}.detail-item .relation[data-v-4664ba3e]{display:inline-block;margin-left:16px;width:150px;text-align:left}.detail-item .phone[data-v-4664ba3e]{display:inline-block;width:185px;margin-left:2px}.detail-item .del[data-v-4664ba3e],.detail-item .edit[data-v-4664ba3e]{cursor:pointer}.detail-item .edit[data-v-4664ba3e]{width:16px;height:16px;margin-right:15px}.teacher[data-v-4664ba3e]{border:none;border-radius:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:424px 48px;position:relative}.teacher .avatar[data-v-4664ba3e]{position:absolute;width:80px;top:-17px;left:0;right:25px}.teacher .mes[data-v-4664ba3e]{position:absolute;left:130px;font-family:Microsoft YaHei;font-weight:700;font-size:16px;color:#d96c36;background:-webkit-gradient(linear,left bottom,left top,from(#e69422),to(#d97a36));background:-webkit-linear-gradient(bottom,#e69422,#d97a36);background:-moz-linear-gradient(bottom,#e69422 0,#d97a36 100%);background:linear-gradient(0deg,#e69422,#d97a36);-webkit-background-clip:text;-webkit-text-fill-color:transparent}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
