<template>
    <div class="center-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="left-content">
                    <global-page-back
                        detailPageTitle="地点管理"
                        @handlerGlobalType="handlerGlobalType"
                    >
                    </global-page-back>
                    <div class="filter-form">
                        <expand-filter :formData="formData" @clickBtn="clickBtn"/>
                    </div>
                    <el-table
                        :data="tableData"
                        id="table"
                        style="width: 100%;margin-bottom: 20px;"
                        row-key="id"
                        border
                        default-expand-all
                        :height="height"
                        :tree-props="{children: 'child', hasChildren: 'hasChildren'}"
                        :header-cell-style="{background:'#fafbfc',color:'#606266'}"
                    >
                        <el-table-column prop="addressName" label="校园地点" :show-overflow-tooltip = 'true' sortable width="180"></el-table-column>
                        <el-table-column prop="" label="所在区域" :show-overflow-tooltip = 'true'  width="180" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.areaName}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="addressType" label="地点类型" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.addressType | filterAddressType }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="描述" :show-overflow-tooltip = 'true' sortable width="180" align="center"></el-table-column>
                        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                        <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                        <el-table-column label="操作" align="center" width="250" fixed="right">
                            <template slot-scope="scope">
                                <xk-button @click="handleEdit(scope.row)" type="success" size="mini">编辑</xk-button>
                                <xk-button @click="add(scope.row.id)" type="primary" size="mini">添加下级</xk-button>
                                <xk-button @click="handleDelete(scope.row)" type="danger" size="mini">删除</xk-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <dialog-wrapper :dialog-obj="dialogObj" @handleClose="handleClose">
                        <dialog-form-data ref="dialogComponent"  :rules-form="rulesForm"
                            :rules="rules" @cancel="handleClose" :optionsData="optionsData"
							:addressTypeList="addressTypeList" @success="handleSubmit"/>

                    </dialog-wrapper>
                </div>
            </div>
        </div>
        <error v-else :error-show="errorShow"/>
    </div>
</template>

<script>
import {
    FilterData,
    Error,
    Loading,
    DialogWrapper, debounce,
} from 'common-local';
// 头部筛选组件
import ExpandFilter from '../../Sub/ExpandFilter'
 // 逻辑运算
 import projectDetails from '@/operations/projectDetails.js';
// 弹框
import dialogFormData from '../equipmentManagement/dialogFormData'
// Ajax 请求
import {DeviceManagementModel} from '@/models/DeviceManagement'
import {
        CommonModel
    } from '@/models/Common.js';
// 自定义颜色
import btnSty from '@/mixins/btnSty.js'
import {mapState} from 'vuex'
import deviceManagementOperates from "@/operations/manageDeviceManagement";
import GlobalPageBack from '../../Sub/GlobalPageBack/index';
const addressTypeList = [
    { label: '餐厅(食堂)', value: 1 },
    { label: '话吧', value: 2 },
    { label: '医务室', value: 3 },
    { label: '超市', value: 4 },
    { label: '图书馆', value: 5 },
    { label: '水房', value: 6 },
    { label: '浴室', value: 7 },
    { label: '其他', value: 8 },
]

export default {
    name: 'DeviceManagementList',
    mixins: [btnSty],
    components: {
        ExpandFilter,
        Error,
        Loading,
        dialogFormData,
        DialogWrapper,
        FilterData,
        GlobalPageBack
    },
    data() {
        return {
            // 静态数据

            // ajax请求
            // 头部请求
            addressTypeList,
            formData: {
                data: [{
                    type: 'input',
                    label: '地点名称',
                    value: '',
                    placeholder: '请输入地点名称',
                    key: 'addressName'
                },
                    {
                        type: 'select',
                        label: '地点类型',
                        value: '',
                        placeholder: '请选择地点类型',
                        key: 'addressType',
                        list: addressTypeList
                    }
                ],
                btnList: [{
                    type: "primary",
                    text: '查询',
                    fn: 'primary'
                },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    },
                    {
                        type: "primary",
                        text: '添加',
                        fn: 'add'
                    }
                ],
            },
            // 功能组件
            loadingShow: false,
            errorShow: false,
            showImport: false,
            tableData: [],
            // 分页数据
            total: 0,
            listQuery: {
                addressName: '',
                addressType: '',
            },
            rulesForm: {
                id:'',
                parentId:'',
                addressName:'',
                addressType:'',
                remark:'',
                schoolId:'',
                areaCascader:[],
            },
            // 弹窗数据
            dialogObj: {
                title: '',
                dialogVisible: false,
                width: '640px'
            },

            rules: {
                addressName:{required:true,message:'请输入校园地点',trigger:blur},
                addressType:{required:true,message:'请选择地点类型',trigger:blur}
            },
            optionsData: [],
            dictList:[],
            parentCodeObj: {
                parentCode: 0,
            },
            parentCodeList: {
                level: ''
            },

            height: 0
            // 表格选中数据
        }
    },
    filters: {
        filterAddressType(data) {
            let find = addressTypeList.find(i =>i.value == data)
            if (find) {
                return find.label
            } else {
                return '--'
            }
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            templateInfo: state => state.templateInfo,
            detailId: state => state.detailId
        })
    },
    created() {
        this.initBaseData()
        this.handleChange();
        this.getRegionsList()
    },
    mounted() {
        setTimeout(() => {
            this.height = document.body.clientHeight - document.getElementById('table').offsetTop - 87
            window.onresize = debounce(() => {
                if (!document.getElementById('table')) return
                const height = document.body.clientHeight - document.getElementById('table').offsetTop - 87
                this.height = height.toString()
            }, 200)
        }, 100)
    },
    methods: {
        /**
         *  初始化
         *  initBaseData   初始化静态数据
         *  getList        列表数据
         *  getStaticList  统计数据
         *  getSchoolList  学校数据
         *  getTypeList    设备类型
         */
        initBaseData() {
            this.deviceManagementModel = new DeviceManagementModel()
            this.getList()
        },
        getList() {

            this.loadingTable = true
            this.listQuery.schoolId = this.schoolId
            this.deviceManagementModel.getAddressList(this.listQuery).then(res => {
                this.handleRes(res, () => {
                    console.log(res,'地址列表数据');

                    this.tableData = res.data.data

                })
            })
        },
        handleDelete(row){
             let deleteList = []
            deleteList.push({
                id:row.id
            })
            console.log(deleteList);
          this.$confirm(row.child.length > 0? '本地点下有子地点，删除后下方的子地点将同时删除，确认删除吗？':'确定删除校园地点吗','删除',{
              confirmButtonText:'确定',
              cancelButtonText:'取消'
          }).then(()=>{
              this.deviceManagementModel.deleteAddress(deleteList).then(res => {
                  this.handleRes(res,()=>{
                      this.$message.success("删除成功！")
                      this.getList()
                  })
              })
          })
        },
        handleEdit(row){
            console.log(row,'rowrowrow')
          this.rulesForm.id = row.id
          this.rulesForm.parentId = row.parentId
            this.rulesForm.addressName = row.addressName
            this.rulesForm.addressType = Number(row.addressType)
            this.rulesForm.remark = row.remark
            this.dialogObj.title = '编辑地点'
            this.dialogObj.dialogVisible = true

             //三级联动 省市区 回显赋值
             let arr = [];
                    arr.push(row['provinceCode'])
                    arr.push(row['cityCode'])
                    arr.push(row['areaCode'])

                    this.rulesForm['areaCascader'] = arr;
                    this.optionsData.forEach((item) => {
                        if (item.value == row['provinceCode']) {
                            console.log(item,'itemitemitemitem')
                            console.log(item,'itemitemitem')
                            item.children = [];
                            let obj = {
                                label: row['cityName'],
                                value: row['cityCode'],
                                children: [
                                    {
                                        label: row['areaName'],
                                        value: row['areaCode'],
                                    }
                                ]
                            }

                            item.children.push(obj)
                            item.children[0].children[0]['leaf'] = true;
                            console.log(this.optionsData,'this.optionsData')

                        }
                    })
        },
        add(parentId){
            this.rulesForm.id = ''
            this.rulesForm.parentId = parentId
            this.dialogObj.title = '添加地点'
            this.dialogObj.dialogVisible = true

        },
        handleSubmit(){
            let areStr =  this.rulesForm['areaCascader'][2] ;
            this.rulesForm.schoolId = this.schoolId;


            this.dictList.forEach((item) => {
                if (areStr == item.areaCode) {
                    this.rulesForm['areaName'] = item.areaName;
                    this.rulesForm['areaCode'] = item.areaCode;
                }
            })


            console.log( this.rulesForm, this.dictList,' this.dictList')
            this.deviceManagementModel.saveAddress(this.rulesForm).then(res => {
                this.handleRes(res,()=>{
                    this.$message.success("新增地点成功")
                    this.getList()
                    this.handleClose()
                })
            })
        },
        handleClose() {
            this.dialogObj.dialogVisible = false
            if (this.$refs['dialogComponent']) {
                this.$refs['dialogComponent'].$refs['dialogForm'].resetFields()
            }
        },
        getRegionsList(fn) {
            console.log('进来没');
                let _this = this;
                const commonModel = new CommonModel();
                commonModel.getAreaDropDown(this.parentCodeObj).then((res) => {
                    console.log(res,'111111111111111111');
                    if (res.data.code === '200') {
                        console.log(res,'111111111111111111');
                        this.dictList = JSON.parse(JSON.stringify(res.data.data))

                        if (_this.parentCodeObj.parentCode == 0) {
                            res.data.data.forEach(function (element, index) {
                                let obj = {
                                    label: element.areaName,
                                    value: element.areaCode.toString(),
                                    children: [{
                                        label: '',
                                        value: '',
                                        children: [{
                                            label: '',
                                            value: '',
                                            children: []
                                        }]
                                    }]
                                }
                                _this.optionsData.push(obj);
                            });
                        } else {
                            projectDetails.getOptionsReg(res.data.data, _this.optionsData, _this.parentCodeList,
                                fn, this.parentCodeObj);
                        }
                    } else if (res.data && res.data.msg) {
                        // throw new Error('公共_区域下拉接口出现错误')
                        this.$message.error(res.data.msg)
                    }
                })
            },
        handleChange() {
                let _this = this;
                this.$eventBus.$off("handleChange")
                this.$eventBus.$on('handleChange', function (filterDate) {
                    console.log(filterDate,'这是？');
                    _this.getAccessData(filterDate)
                    _this.parentCodeList.level = filterDate.level;
                    _this.parentCodeObj.parentCode = filterDate.id;
                    _this.getRegionsList({
                        fn: filterDate.fn,
                        node: filterDate.node
                    });

                })
            },
            getAccessData (filterDate) {
                if (filterDate.level == 1) {
                    this.rulesForm['provinceCode'] = filterDate.id;
                    this.rulesForm['provinceName'] = filterDate.node.label;
                }

                if (filterDate.level == 2) {
                    this.rulesForm['cityCode'] = filterDate.id;
                    this.rulesForm['cityName'] = filterDate.node.label;
                    // this.rulesForm['areaCascader'] = filterDate.node.label;
                }
                if (filterDate.level == 3) {
                    this.rulesForm['areaCode'] = filterDate.id;
                    this.rulesForm['areaName'] = filterDate.node.label;
                }
                console.log(this.rulesForm,'this.rulesForm')
            },
        clickBtn(data) {
            switch (data.item.fn) {
                case 'primary': //查询
                    this.formData.data.forEach(item => {
                        this.listQuery[item.key] = item.value
                    })

                    this.getList()
                    break;
                case 'reset': //重置
                    this.formData.data.forEach((item) => {
                        item.value = ''
                        this.listQuery[item.key] = ''
                    })
                    this.getList()
                    break;
                case 'add': //新增
                    this.add('')
                    break;
            }
        },



        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        handlerGlobalType () {
            this.$emit("handlerGlobalType","list")
        }

    },
}
</script>

<style lang="scss" scoped>
    .center-main {
        padding: 0px;
        .filter-form {
            margin-top: 10px;
        }
    }
</style>
